<script setup>
const userStore = useUserStore();
definePageMeta({
  title: 'Genoch - Client Sign In',
  layout: 'simple'
});

const signInForm = ref({
  email: '',
  password: ''
});

async function signIn() {
  await userStore.signIn(signInForm.value);
}
</script>

<template>
  <div class="wrapper">
    <h2>Sign In.</h2>
    <div class="signin-form">
      <input
        id="email"
        v-model="signInForm.email"
        type="email"
        name="email"
        placeholder="Enter your email"
      >
      <input
        id="password"
        v-model="signInForm.password"
        type="password"
        name="password"
        placeholder="Enter your password"
        @keyup.enter="() => { signIn() }"
      >
      <button
        @click="() => { signIn() }"
      >
        Sign In
      </button>

      <div class="signup">
        <span>
          Don't have an account?
        </span>
        <a href="https://genoch.com/contact/">
          Become a client today
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper {
    @apply flex flex-col flex-1 content-stretch items-center justify-start gap-8 p-16;
    background: url('~/assets/images/bg-pattern.png') no-repeat center center fixed;
}

.wrapper h2 {
    @apply text-4xl tracking-wide font-bold text-gray-200;
}

.signin-form {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4 p-20 pb-14 rounded-[2rem];
    @apply bg-gradient-to-br from-[#2B2C41] from-30% to-[#12131c] shadow-lg shadow-gray-800;
}

.signin-form > input {
    @apply p-4 rounded-xl min-w-[300px];
    @apply bg-gray-500 text-gray-100 placeholder:text-gray-100 placeholder:text-center text-center;
}

.signin-form > button {
    @apply btn mt-4;
}

.signup {
  @apply mt-6;
  @apply text-sm text-gray-200;
}

.signup > a {
  @apply font-medium text-secondary-400;
}
</style>
